// ImageSlider.jsx

import React, { useState, useEffect } from 'react';
// import './ImageSlider.css';
// import TopContentImage1 from "../Assets/Image (5).jpg";
// import TopContentImage2 from "../assets-img/about.jpg";
// import TopContentImage4 from "../assets-img/pexels-rene-asmussen-5157260.jpg";
// import TopContentImage5 from "../Assets/pexels-dids-1302307.jpg";
import TopContentImage6 from "../Assets/pexels-pixabay-266621.webp";
// import TopContentImage3 from "../assets-img/product (4).jpg";
import TopContentImage7 from "../Assets/pexels-megapixelstock-17834.webp";
import TopContentImage8 from "../Assets/pexels-git-stephen-gitau-1670723.webp";


const ImageSlider = () => {
  const images = [
    // TopContentImage3,
    TopContentImage7,
    TopContentImage6,
    // TopContentImage5,
    // TopContentImage4,
    TopContentImage8
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // const handlePrev = () => {
  //   setCurrentImageIndex((prevIndex) =>
  //     prevIndex === 0 ? images.length - 1 : prevIndex - 1
  //   );
  // };

  // Auto-slide every 3 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNext();
    }, 3000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [currentImageIndex]);

  return (
    <div className="image-slider-container">
      <img
        src={images[currentImageIndex]}
        alt={`Image ${currentImageIndex + 1}`}
        className="slider-image"
      />
      {/* <button className="slider-button" onClick={handlePrev}>
        Previous
      </button>
      <button className="slider-button" onClick={handleNext}>
        Next
      </button> */}
    </div>
  );
};

export default ImageSlider;
