import axios from "axios";

export const axiosInstance = axios.create({
    // baseURL: 'http://localhost/react',
    baseURL: 'https://renesmeejewel.com/php',    
    withCredentials: true, 
});


    
