import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../utils";

const UserOrderList = () => {
  const [orderList, setOrderList] = useState([]);

  useEffect(() => {
    const fetchOrderList = async () => {
      const storedUserDetail = localStorage.getItem("user");
      // Parse the stored data if it exists
      if (storedUserDetail) {
        const parsedUserDetail = JSON.parse(storedUserDetail);

        try {
          const response = await axiosInstance.get(
            `/OrderListData.php?email=${parsedUserDetail[0].email}&mobile=${parsedUserDetail[0].mobile}`
          );
          setOrderList(response.data.items);
        } catch (error) {
          console.error("Error fetching order list:", error);
        }
      }
    };

    fetchOrderList();
  }, []);
  const reversedList = orderList.slice().reverse(); 
  return (
    <div className="table-container">
      <h3 style={{ textAlign: "center", margin: "10px" }}>OrderList</h3>
      <table className="table">
        <thead>
          <tr>
            <th>SNo</th>
            <th>name</th>
            <th>mobile</th>
            <th>email</th>
            <th>amount</th>
            <th>transactionId</th>
            <th>address</th>
            <th>Date/Time</th>
            <th>items</th>
          </tr>
        </thead>
        <tbody>
          {reversedList.map((item, index) => (
            <tr key={index}>
              <td data-label="SNo">{index + 1}</td>
              <td data-label="name">{item.name}</td>
              <td data-label="mobile">{item.mobile}</td>
              <td data-label="email">{item.email}</td>
              <td data-label="amount">{item.amount}</td>
              <td data-label="transactionId">{item.transactionId}</td>
              <td data-label="address">{item.address}</td>
              <td data-label="address"><p>{item.Date}</p><p>{item.Time}</p></td>
              <td data-label="items" style={{ width: "30%" }}>
                {JSON.parse(item.items).map((subItem, subIndex) => (
                  <div key={subIndex} className="item-details">
                    <p>
                      <strong>ID:</strong> {subItem.id}
                    </p>
                    <p>
                      <strong>Name:</strong> {subItem.name}
                    </p>
                    <p>
                      <strong>Price:</strong> {subItem.price}
                    </p>
                    <p>
                      <strong>Quantity:</strong> {subItem.quantity}
                    </p>
                    <p>
                      <strong>Image:</strong>{" "}
                      <img
                        style={{ width: "80px" }}
                        href={subItem.image}
                        rel="noopener noreferrer"
                      />
                    </p>
                  </div>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserOrderList;
