import React, { useContext, useEffect, useState } from "react";
import { axiosInstance } from "../../utils";
// import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { MyContext } from "../MyApp";

function Products() {
  const [products, setProducts] = useState([]);
  const [searchproduct, setSearchproducts] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  // const [material, setMaterial] = useState(false);
  const [size, setSize] = useState(false);
  const [type, setType] = useState(false);
  const [gemstone, setGemstone] = useState(false);
  const { setLoading } = useContext(MyContext);

  // const materialoption = () => {
  //   setMaterial(!material);
  // };

  // const sizeoption = () => {
  //   setSize(!size);
  // };

  // const typeoption = () => {
  //   setType(!type);
  // };

  // const gemoption = () => {
  //   setGemstone(!gemstone);
  // };

  const { LinkValue } = useParams();
  useEffect(() => {
    searchproductindatabase1();
  }, [LinkValue]);

  const searchproductindatabase1 = () => {
    setLoading(true);
    const url = "/getProducts.php";
    let fData = new FormData();
    axiosInstance
      .post(url, fData)
      .then((response) => {
        const GetData = response.data.products;
        setProducts(GetData);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        alert("Error");
      });
    setLoading(false);
  };

  const searchproductindatabase = () => {
    setLoading(true);
    const url = "/searchproduct.php";

    let fData = new FormData();
    fData.append("searchproduct", searchproduct);

    axiosInstance
      .post(url, fData)
      .then((response) => {
        const searchData = response.data.products;
        setProducts(searchData);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        alert("Error");
      });
    setLoading(false);
  };

  useEffect(() => {
    searchproductindatabase1();
  }, []);

  // Fetch all products function
  const fetchAllProducts = async () => {
    setLoading(true);

    const res = await axiosInstance.post("/getProducts.php");
    if (res?.data) {
      setProducts(res.data.products);
    }
    setLoading(false);
  };

  const handleSelectChange = (e) => {
    setLoading(true);
    const value = e.target.value;

    setSelectedValue(value);
    console.log(value);
    if (value === "high") {
      const url = "/sortproduct.php";
      let fData = new FormData();
      fData.append("value", value);
      axiosInstance
        .post(url, fData)
        .then((response) => {
          console.log(response.data);
          setProducts(response.data.products);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          alert("Error");
        });
    } else if (value === "low") {
      const url = "/sortproduct.php";
      setLoading(true);

      let fData = new FormData();
      fData.append("value", value);
      axiosInstance
        .post(url, fData)
        .then((response) => {
          console.log(response.data);
          setProducts(response.data.products);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          alert("Error");
        });
    } else if (value === "new") {
      const url = "/sortproduct.php";
      setLoading(true);
      let fData = new FormData();
      fData.append("value", value);
      axiosInstance
        .post(url, fData)

        .catch((error) => {
          console.log(error);
          alert("Error");
          setLoading(false);
        });
    } else if (value === "reccommanded") {
      const url = "/sortproduct.php";
      setLoading(true);

      let fData = new FormData();
      fData.append("value", value);
      axiosInstance
        .post(url, fData)

        .catch((error) => {
          console.log(error);
          alert("Error");
          setLoading(false);
        });
    }
  };

  const [rangeValue, setRangeValue] = useState(10000);

  const updateValue = (event) => {
    setRangeValue(event.target.value);
  };

  // Function to filter products with price less than 1000
  function filterProductsLessThan1000(products) {
    console.log(rangeValue);
    return products.filter((product) => product.price < rangeValue);
  }

  // Example usage: Filter products with price less than 1000
  const filteredProducts = filterProductsLessThan1000(products);
  console.log(filteredProducts);

  return (
    <div>
      <h1 className="page-heading">Products</h1>
      <div className="products-mainpage">
        <div className="search-box-product">
          <div className="group">
            <svg className="icon" aria-hidden="true" viewBox="0 0 24 24">
              <svg class="icon" aria-hidden="true" viewBox="0 0 24 24">
                <g>
                  <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                </g>
              </svg>
            </svg>
            <input
              placeholder="Search"
              type="search"
              className="input"
              value={searchproduct}
              onChange={(e) => {
                setSearchproducts(e.target.value);
                // Check if the search input is empty and fetch all products if it is
                if (e.target.value.trim() === "") {
                  fetchAllProducts();
                } else {
                  searchproductindatabase();
                }
              }}
            />
          </div>
          {/* <div style={{marginRight:"40px"}} className='filter-product'>
       Filter Product
        </div> */}

          <div className="filter-product-1 ">
            Sort By :
            <select
              name=""
              id=""
              className="select-shot"
              onChange={handleSelectChange}
              value={selectedValue}
            >
              <option value="">None</option>
              <option value="high">Price : High To Low</option>

              <option value="low">Price : Low To High</option>

              <option value="new">Whats New</option>

              <option value="reccommanded">Recommanded</option>
            </select>
          </div>

          <div className="filterproduct">
            <div
              className="filterproductcolumn"
              style={{
                borderTop: "1px solid rgb(185, 185, 185)",
                justifyContent: "center",
                padding: "0",
              }}
            >
              <p className="filter-option"> Price range</p>{" "}
              {/* <i class="fa-solid fa-angle-right"></i>{" "} */}
            </div>
            <div className="filterproductcolumn">
              <input
                style={{ width: "68%", margin: "0" }}
                type="range"
                min="0"
                max="10000"
                step="1"
                name="yourRange"
                id="yourRange"
                onChange={updateValue}
              />
              <h1 className="price-range-filter">
                {rangeValue}{" "}
                <i
                  style={{ paddingRight: "5px" }}
                  class="fa-solid fa-indian-rupee-sign"
                ></i>{" "}
              </h1>
            </div>
            {/* <div className="filterproductcolumn" onClick={materialoption}>
              <p className="filter-option">Metal</p>{" "}
              <i class="fa-solid fa-angle-right"></i>
            </div>
            {material && (
              <div className="material-options">
                <div className="filterproductcolumn-1">
                  <p>GOLD</p>
                </div>
                <div className="filterproductcolumn-1">
                  <p>SILVER</p>
                </div>
                <div className="filterproductcolumn-1">
                  <p>PLATINUM</p>
                </div>
                <div className="filterproductcolumn-1">
                  <p>DIAMOND</p>
                </div>
              </div>
            )} */}
            {/* <div className="filterproductcolumn" onClick={sizeoption}>
              <p className="filter-option">Size</p>{" "}
              <i class="fa-solid fa-angle-right"></i>
            </div>
            {size && (
              <div className="material-options">
                <div className="filterproductcolumn-1">
                  <p>Option 1</p>
                </div>
                <div className="filterproductcolumn-1">
                  <p>option 2</p>
                </div>
                <div className="filterproductcolumn-1">
                  <p>option 3</p>
                </div>
                <div className="filterproductcolumn-1">
                  <p>option4</p>
                </div>
              </div>
            )} */}
            {/* <div className="filterproductcolumn" onClick={typeoption}>
              <p className="filter-option">Type</p>{" "}
              <i class="fa-solid fa-angle-right"></i>
            </div>
            {type && (
              <div className="material-options">
                <div className="filterproductcolumn-1">
                  <p>GOLD</p>
                </div>
                <div className="filterproductcolumn-1">
                  <p>SILVER</p>
                </div>
                <div className="filterproductcolumn-1">
                  <p>PLATINUM</p>
                </div>
                <div className="filterproductcolumn-1">
                  <p>DIAMOND</p>
                </div>
              </div>
            )} */}
            {/* <div className="filterproductcolumn" onClick={gemoption}>
              <p className="filter-option">Gemstones</p>{" "}
              <i class="fa-solid fa-angle-right"></i>
            </div> */}
            {/* {gemstone && (
              // <div className="material-options">
              //   <div className="filterproductcolumn-1">
              //     <p>GOLD</p>
              //   </div>
              //   <div className="filterproductcolumn-1">
              //     <p>SILVER</p>
              //   </div>
              //   <div className="filterproductcolumn-1">
              //     <p>PLATINUM</p>
              //   </div>
              //   <div className="filterproductcolumn-1">
              //     <p>DIAMOND</p>
              //   </div>
              // </div>
            )} */}
          </div>
        </div>

        <div className="product-grid">
          {filteredProducts.map((product) => (
            <Link
              to={`/ProductDetailPage/${product.id}`}
              className="ProductViewer"
              key={product.id}
            >
              <i class="fa-regular fa-heart favorite-button"></i>
              <div className="ProductViewer-details">
                <img
                  className="product-image-main"
                  alt=""
                  src={product.image}
                />
                <p className="text-title">{product.name.toUpperCase()}</p>
                {/* <p className="text-body">Price : {product.price === "0" ? "Not available" : `${product.price} ${<i style={{ fontSize: "12px" }} className="fa-solid fa-indian-rupee-sign"></i>} `} </p> */}
                <p className="text-body">
                  {product.price === "0"
                    ? "Sold Out"
                    : `Price: ${product.price} ₹`}
                </p>

                {product.weight > 0 ? (
                  <p className="text-body">
                    {product.weight === ""
                      ? ""
                      : `Weight : ${product.weight} gm`}{" "}
                  </p>
                ) : (
                  <></>
                )}
                <p className="text-body-1">{product.Description}</p>
              </div>

              <div className="ProductViewer-button">
                Shop Now
                <i
                  style={{ color: "white", marginLeft: "5px" }}
                  className="fa-solid fa-cart-shopping"
                ></i>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Products;
