import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import logo from "../Assets/Renesmee-logo.jpg";

const Mobilsnav = (props , {itemCount , onClose }) => {
  console.log(props.itemCount)
    const [animationClass, setAnimationClass] = useState('');

    const [subCate , setSubCate] = useState(false)

    const category =()=>{
      setSubCate(!subCate)
    }

    useEffect(() => {
        setAnimationClass(props.handleclick ? 'open' : 'closed');
      }, [props.handleclick]);
    
      const onAnimationEnd = () => {
        if (!props.handleclick) {
          setAnimationClass(''); // Reset animation class when closing animation ends
          onClose(); // Trigger any additional close-related actions
        }
      };

      const phoneNumber = '9870284371'; // Replace with the desired phone number
      const message = ' '; // Replace with your desired message
    
      const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

      const phoneNumber1 = '9870284371'; // Replace with the desired phone number

      const phoneCallLink = `tel:${phoneNumber1}`;

      const categoryr = "rings";
      const categoryp = "pendants";
      const categoryn = "neckless";
      const categoryb = "bracelet";
      const categoryc = "chain";
      const categoryO = "ornaments";
      const categorya = "earring";
      const categorynp = "nosepin";
      
  return (
   
        // <div className={`mobilenavbar ${isOpen ? 'open' : ''}`}>
             <div className={`mobilenavbar ${animationClass}`} onAnimationEnd={onAnimationEnd}>
              <div className='mobile-logo'>
              <img className="logo-nav1" src={logo} alt="" />
              </div>
            {/* <div className='mobilenavbar'> */}
        <i className="mobilecross fa-solid fa-xmark" onClick={props.handleclick}></i> 
        <div className='mobilenav-1'>
        <Link to="/" className="mobile-nav-link home-link" onClick={props.handleclick}>
        <i style={{paddingLeft:"30px",paddingRight:"30px"}}  class="fa-solid fa-house"></i>  
         HOME
         
        </Link>
        <div onClick={category}  to="" className="mobile-nav-link cato-link">
        <i style={{paddingLeft:"30px",paddingRight:"30px"}} class="fa-solid fa-list"></i>     JEWELLERY   <i style={{paddingLeft:"145px"}} class="fa-solid fa-chevron-down"></i>
        </div>

        {subCate && (
          <div className="">

       <div className="sub-cate-mobile">
              <Link  to={`/Products/${categoryr}`} className="sub-cate-head" onClick={props.handleclick}>
              Baguette  Silver Collections
              </Link>
              <Link  to={`/Products/${categoryr}`} className="sub-cate-link-mob" onClick={props.handleclick}>
                Rings
              </Link>
              <Link  to={`/Products/${categoryb}`} className="sub-cate-link-mob" onClick={props.handleclick}>
                Bracelet
              </Link>
              <Link  to={`/Products/${categoryc}`} className="sub-cate-link-mob" onClick={props.handleclick}>
                Chain Set
              </Link>
              {/* <Link  to={`/Products/${categoryr}`} className="sub-cate-link-mob" onClick={props.handleclick}>
                Payal
              </Link> */}
              {/* </div>
              <div className="sub-cate-mobile"> */}
              {/* <Link  to={`/Products/${categoryr}`} className="sub-cate-head" onClick={props.handleclick}>
                Gold
              </Link> */}
              <Link  to={`/Products/${categoryO}`} className="sub-cate-link-mob" onClick={props.handleclick}>
                ornaments
              </Link>
              <Link  to={`/Products/${categorya}`} className="sub-cate-link-mob" onClick={props.handleclick}>
                Earring
              </Link>
              {/* <Link  to={`/Products/${categoryr}`} className="sub-cate-link-mob" onClick={props.handleclick}>
                Coins for gift
              </Link> */}
              <Link  to={`/Products/${categorynp}`} className="sub-cate-link-mob" onClick={props.handleclick}>
                Nosepin
              </Link>
            {/* </div>
            <div className="sub-cate-mobile"> */}
              {/* <Link  to={`/Products/${categoryr}`} className="sub-cate-head" onClick={props.handleclick}>
                Diamond
              </Link> */}
              <Link  to={`/Products/${categoryr}`} className="sub-cate-link-mob" onClick={props.handleclick}>
                Love & Engagement
              </Link>
              {/* <Link  to={`/Products/${categoryr}`} className="sub-cate-link-mob" onClick={props.handleclick}>
                Nosepin
              </Link> */}
              <Link  to={`/Products/${categoryp}`} className="sub-cate-link-mob" onClick={props.handleclick}>
                Pendants
              </Link>
              <Link  to={`/Products/${categoryn}`} className="sub-cate-link-mob" onClick={props.handleclick}>
                Necklace
              </Link>
            </div>
            <div>
              {/* <img className="nav-image" src={navimage1} alt="" /> */}
            </div>
            <div>
              {/* <img className="nav-image" src={navimage} alt="" /> */}
            </div>
            {/* <div>
            <img className="nav-image" src={navimage2} alt="" />
          </div> */}
          </div>
          )}


        <Link to="/aboutus" className="mobile-nav-link about-link" onClick={props.handleclick}>
        <i style={{paddingLeft:"30px",paddingRight:"30px"}} class="fa-solid fa-ring"></i> ABOUT
        </Link>
        
        <Link to="/ContactUs" className="mobile-nav-link contact-link" onClick={props.handleclick}>
        <i style={{paddingLeft:"30px",paddingRight:"30px"}} class="fa-solid fa-address-book"></i>     INTERACT US
        </Link>

        <Link to="/cartpage" className="mobile-nav-link contact-link" onClick={props.handleclick}>
        <i style={{paddingLeft:"30px",paddingRight:"30px"}} class="fa-solid fa-cart-shopping"></i>  CART  <div style={{paddingLeft:"135px"}} className="cart-number">
            {props.itemCount || ""} 
            </div> 
        </Link>


        <a href={whatsappLink} className="mobile-nav-link contact-link" onClick={props.handleclick}>
        <i style={{paddingLeft:"30px",paddingRight:"30px"}} class="fa-brands fa-whatsapp"></i>  WHATSAPP
        </a>

        <a to="https://www.instagram.com/renesmeejewel_official/" className="mobile-nav-link contact-link" onClick={props.handleclick}>
        <i style={{paddingLeft:"30px",paddingRight:"30px"}} class="fa-brands fa-instagram"></i>  INSTAGRAM
        </a>

        <Link to="/" className="mobile-nav-link contact-link" onClick={props.handleclick}>
        <i style={{paddingLeft:"30px",paddingRight:"30px"}} class="fa-solid fa-cart-shopping"></i>  MY ORDERS
        </Link>

        <a href={phoneCallLink} className="mobile-nav-link contact-link" onClick={props.handleclick}>
        <i style={{paddingLeft:"30px",paddingRight:"30px"}} class="fa-solid fa-phone"></i>  CALL US
        </a>


      
        </div>
    </div>
      
  )
}

export default Mobilsnav