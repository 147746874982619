import React from "react";
import "../Css/Style.css";
import ProductCard from "../Components/ProductCard";
// import TopContentImage1  from "../Assets/Image (1).jpg";
// import TopContentImage2  from "../Assets/Image (2).jpg";
import TopContentImage3 from "../Assets/Image (3).jpg";
// import TopContentImage4  from "../Assets/Image (4).jpg";
import TopContentImage5 from "../Assets/Image (5).jpg";
import TopContentImage6 from "../Assets/Image (6).jpg";
import TopContentImage7 from "../Assets/Image (7).jpg";
import TopContentImage8 from "../Assets/Image (8).jpg";
// import TopContentImage9  from "../Assets/Image (9).jpg";
import TopContentImage10 from "../Assets/Image (1).webp";
import ImageSlider from "../Components/SliderMain";
import ProductSlider from "../Components/ProductSlider";
import { Link } from "react-router-dom";
import SilmilerProducts from "../Components/SilmilerProducts";

const HomePage = () => {
  return (
    <div className="content-box">
      <div className="content-top">
        <div className="content-top-left"></div>
        <div className="content-top-right"></div>
      </div>
      {/* <ImageSlider/> */}

      <div className="content-top-2">
        <h1 className="heading-main">RENESMEE JEWEL</h1>
        <div className="content-top-2-down">
          <div className="content-top-2-left">
            {/* <h1 className="headline-content">HEADING</h1> */}
            <p className="content-paragraph">
              Renesmee means born out of love but this Renesmee born Out of
              Dream,it was started in 2014 when I saw a dream of a spacious
              jewelry shop that time I was working in jewellery showroom and I
              thought the shop I saw in my dream it’s a extended version of
              current shop so I spoke with my Boss[Sir] about that and he was so
              happy and decided to buy a shop and they bought in 2018 and now
              its running successfully by the Grace of God but there is
              something I was missing ,I also wanted to do something but to
              manage a jewellry shop soley it beyond impossible for a working
              women but at that sharp time.
            </p>
            <br />
            <p className="content-paragraph">
            I was inspired by God to open a E
              commerce showroom this thought came in mind instantly and now a
              dream over dream in front of you all, so what I learned from this
              is when you see a dream for someonelse and dedicated yourself to
              fullfilled there dream God do it for you surley greater than them
              we always talk about hard work,effort ,dedication one think I want
              to add in this which is a spirit of all is well wishing,be a well
              wisher in any sitution even if it is against you even if you bare
              a loss God is waiting to give so much then you give to someone
              else.
            </p>
          </div>
          <div className="content-top-2-right"></div>
        </div>
      </div>


      <ProductSlider />

      <div className="content-top-2-1">
        <div className="content-top-2-down-1">
          <div className="content-top-2-left">
            <h1 className="headline-content">Make your jewelry Last longer</h1>
            <p className="content-paragraph">
            Surely your jewelry should be keep in the jewelry box after using it .
            
            <br />   <br /> Keep it dry
mildly rubbing with silver cleaning cloth will restore the orignal luminosity.
<br /><br />
You can buy from silver cleaning cloth from our website.
Do not use harsh soap or cleanser on jewellry try to remove your jewelry before bed,swimming and makeup because of metal, stone and diamond sensitivity.
<br /><br />
Regular use of jewelry and after a significant period of time re-polish your ornaments so it will unfaded.
            </p>
            <br /><br />
        
          </div>
          <div className="content-top-2-right-down"></div>
        </div>
      </div>
      <SilmilerProducts />


      <h1 className="heading-main">Products you know</h1>
      <div className="conntent-middle">
        <div className="product-card-box">
          <ProductCard image={TopContentImage3} name="NECKLESS" desc="" />
          <ProductCard image={TopContentImage10} name="EARINGS" desc="" />
          <ProductCard image={TopContentImage5} name="RING" desc="" />
        </div>
        <div className="product-card-box">
          <ProductCard image={TopContentImage7} name="RINGS" desc="" />
          <ProductCard image={TopContentImage6} name="SILVER RING" desc="" />
          <ProductCard image={TopContentImage8} name="BAGGIT RING" desc="" />
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "auto",
          marginBottom: "50px",
          position: "relative",
        }}
      >
        <Link to="/product" className="shop-btn">
          SHOP NOW
        </Link>
        <ImageSlider />
      </div>

      {/* <ProductSlider  /> */}
    </div>
  );
};

export default HomePage;
