import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../MyApp";
import { axiosInstance } from "../../utils";

const Orderlist = () => {
  const { setLoading } = useContext(MyContext);
  useEffect(() => {
    searchproductindatabase1();
  }, []);
  const [OrderList, setOrderList] = useState([]);

  const searchproductindatabase1 = () => {
    setLoading(true);
    const url = "/OrderListData.php";
    let fData = new FormData();
    axiosInstance
      .post(url, fData)
      .then((response) => {
        const GetData = response.data.items;
        setOrderList(GetData);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        alert("Error");
      });
  };
  const reversedList = OrderList.slice().reverse(); 
  return (
    <div className="table-container">
      <h3 style={{textAlign:"center", margin:"10px"}}>OrderList</h3>
      <table className="table">
        <thead>
          <tr>
            <th>SNo</th>
            <th>name</th>
            <th>mobile</th>
            <th>email</th>
            <th>amount</th>
            <th>transactionId</th>
            <th>address</th>
            <th>Date/Time</th>

            <th>items</th>
          </tr>
        </thead>
        <tbody>
        {reversedList.map((item, index) => (
            <tr key={index}>
              <td data-label="SNo">{index + 1}</td>
              <td data-label="name">{item.name}</td>
              <td data-label="mobile">{item.mobile}</td>
              <td data-label="email">{item.email}</td>
              <td data-label="amount">{item.amount}</td>
              <td data-label="transactionId">{item.transactionId}</td>
              <td data-label="address">{item.address}</td>
              <td data-label="address"><p>{item.Date}</p><p>{item.Time}</p></td>
              <td data-label="items" style={{ width: "30%" }}>
                {JSON.parse(item.items).map((subItem, subIndex) => (
                  <div key={subIndex} className="item-details">
                    <p>
                      <strong>ID:</strong> {subItem.id}
                    </p>
                    <p>
                      <strong>Name:</strong> {subItem.name}
                    </p>
                    <p>
                      <strong>Price:</strong> {subItem.price}
                    </p>
                    <p>
                      <strong>Quantity:</strong> {subItem.quantity}
                    </p>
                    <p>
                      <strong>Image:</strong>{" "}
                      <img
                        style={{ width: "100px"  , height:"100px"}}
                        href={subItem.image}
                        rel="noopener noreferrer"
                      />
                    </p>
                  </div>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Orderlist;
