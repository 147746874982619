import React, { useState } from "react";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../utils";

const ContactUs = () => {
  const gotopage = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [comment, setComment] = useState("");

  const handleFormSubmit = () => {
    console.log("name:", name);
    console.log("email:", email);
    console.log("mobile:", mobile);
    console.log("comment:", comment);

    if (name.length === 0) {
      alert("Name has left Blank");
    } else if (email.length === 0) {
      alert("email has left Blank");
    } else if (mobile.length === "") {
      alert("mobile has left Blank");
    } else if (comment.length === 0) {
      alert("comment has left Blank");
    } else {
      const url = "/form.php";

      let fData = new FormData();
      fData.append("name", name);
      fData.append("email", email);
      fData.append("mobile", mobile);
      fData.append("comment", comment);

      axiosInstance
        .post(url, fData)
        .then((response) => {
          console.log(response.data);
          if (response.data !== "401") {
            alert(
              "ThankYou For Your Response We Will Connect With You Shortly"
            );
            setTimeout(function () {
              gotopage("/");
            }, 100);
          } else {
            alert("You Have Entered Wrong Userid Or Password");
          }
        })
        .catch((error) => {
          console.log(error);
          alert("Error");
        });
    }
  };

  const phoneNumber = '9870284371'; // Replace with the desired phone number
  const message = ' '; // Replace with your desired message

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <div>
      <div className="about-us-container">

 
      <h1 className="heading-main hide">Interact US</h1>

      
      <div className="contact-form">
        <div className="contact-form-left">
          <h1 className="heading-main">
            Contact details <i className="fa-solid fa-store"></i>
          </h1>
          {/* <h3>
            Address <i className="fa-solid fa-location-dot"></i>
          </h3>
          <p>Street 24 beside flipcart mart Mumbai India</p> */}
          <div className="contact-form-1">
            <div>
              <h3 className="contact-us-txt">
                Email <i className="fa-solid fa-envelope-open-text"></i>
              </h3>
              <p> info@renesmeejewel.com </p>
              {/* <p>abc@gmil.com</p> */}
            </div>
            <div>
              <h3 className="contact-us-txt">
                Phone No <i className="fa-solid fa-phone"></i>
              </h3>
              <p  className="contact-us-txt">+91 89284 59292</p>
              {/* <p  className="contact-us-txt">8888888888</p> */}
            </div>
          </div>
          <ul className="wrapper">
    <a href="https://www.facebook.com/profile.php?id=61553984426893" className="icon facebook">
        <span className="tooltip">Facebook</span>
        <span><i className="fab fa-facebook-f"></i></span>
    </a>
    <a  href={whatsappLink} target="_blank" rel="noopener noreferrer" className="icon twitter">
        <span className="tooltip">Whatsapp</span>
        <span><i className="fab fa-whatsapp"></i></span>
    </a>
    <a  href="https://www.instagram.com/renesmeejewel_official/" className="icon instagram">
        <span className="tooltip">Instagram</span>
        <span><i className="fab fa-instagram"></i></span>
    </a>
    <a href="  https://youtube.com/@RenesmeeJewel?si=FvCj1_W3afaM55BG" className="icon youtube">
        <span className="tooltip">Youtube</span>
        <span><i className="fa-brands fa-youtube"></i></span>
    </a>
  
</ul>
        </div>

        <div className="contact-form-right">
          <h1 className="heading-main">Contact Details </h1>

          <div style={{ marginTop: "20px" }}>
            <p className="name">
              <input
                name="name"
                type="text"
                className="feedback-input"
                placeholder="Name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </p>

            <p className="email">
              <input
                name="email"
                type="text"
                className="feedback-input"
                id="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </p>

            <p className="email">
              <input
                name="number"
                type="text"
                className="feedback-input"
                id="mobile"
                placeholder="Mobile Number"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </p>

            <p className="text">
              <input
                name="text"
                className="feedback-input"
                id="comment"
                placeholder="Comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </p>

            <div className="submit">
              <input
                type="button"
                id="button-blue"
                value="SEND !"
                onClick={handleFormSubmit}
              />
            </div>
          </div>
        </div>
      </div>

      
      <p className="contact-us-heading">
        Please mail us at @info@renesmeejewel.com for any enquires.we will try and replying withing 48
        hours of working days
      </p>

      <div className="about-container">
        <h1 className="heading-main">Orders & Specialization</h1>
      </div>
      <div>
        <p className="content-paragraph">
          please contact us for customizations orders.we would like to inform
          our customers that we also manufacture Diamond and Gold Jewelry. For
          further information on the above .please mail us at hey@renesmee.com
          we would love to create stunning jewelry for you.
        </p>
      </div>

      <div className="about-container">
        <h1 className="heading-main">Shipping Policy Free Shipping</h1>
      </div>
      <div>
        <p className="content-paragraph">
          <li>
            Renesmee Insure that your order reaches you in the fastest time and
            in Good Condition.
          </li>
          <li>
            Usually orders ship and delivered in 10-12 days .in fortunate
            cases,a slight delay might be caused due to natural causes and Govt
            Holidays.
          </li>
        </p>
        {/* <p className="content-paragraph">
            - <strong>Quality Products:</strong> We source our products from
            reputable suppliers to ensure you receive only the best.
          </p>
        </div>
        <div>
          <p className="content-paragraph">
            - <strong>Customer-Centric Approach:</strong> Your satisfaction is
            our priority. Our dedicated support team is here to assist you at
            every step.
          </p>
        </div>

        <div>
          <p className="content-paragraph">
            - <strong>Secure Shopping:</strong> We take your privacy seriously.
            Our secure payment gateways and robust data protection measures
            ensure a safe shopping environment.
          </p> */}
      </div>

      <div className="about-container">
        <h1 className="heading-main">Make your jewelry Last longer</h1>
      </div>

      <div>
        <p className="content-paragraph">
          <li>
            Surely your jewelry should be keep in the jewelry box after using it
            .keep it dry
          </li>
          <li>
            mildly rubbing with silver cleaning cloth will restore the orignal
            luminosity.you can buy from silver cleaning cloth from our website.
          </li>
          <li>Do not harsh soap or cleanser on jewellry</li>
          <li>
            try to remove your jewelry before bed,swimming and makeup because of
            metal, stone and diamond sensitivity.
          </li>
          <li>
            regular use of jewelry and after a significant period of time
            re-polish your ornaments so it will unfaded.
          </li>
        </p>
      </div>

      <div className="about-container">
        <h1 className="heading-main">Privacy Pollicy</h1>
      </div>

      <div>
        <p className="content-paragraph">
          <li>
            You may not copy, post ,display ,download ,reproduce and republish or
            any part of the website. <br />
          </li>

          <li>
            To pretect and secure your personal information is one of Highest
            resposibility of Renesmee.
            <br />
          </li>

          <li>
            The information you provide is reffered as personal
            information.kindly make sure you update your information if any
            change.
            <br />
          </li>

          <li>
            Renesmee use your provide information only when to fulfill your
            order and to communicate.
            <br />
          </li>

          <li style={{marginBottom:"50px"}}>for any further question,please conatact us @renesmee</li>
        </p>
      </div>
      </div>

    </div>
  );
};

export default ContactUs;
