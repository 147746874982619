import React, { useEffect, useState } from "react";
import "../Css/CartPage.css";
import { axiosInstance } from "../../utils";
import Modal from "../Components/Modal";
import Payment from "../Components/Payment";
import EmptyCart from "../Assets/empty-cart.png"
// import axios from 'axios';

const CartPage = ({ setItemCount, setName , loggedin }, props) => {
  const [items, setItems] = useState([]); // Initialize items as an empty array
  const [totalValue, setTotalValue] = useState(0);
   const [payment , setPayment] = useState(false)
console.log(items)

  useEffect(() => {
    async function getItemsInCart() {
      const res = await axiosInstance.post("/getItemsInCart.php");
      if (res?.data) {
        setItems(res.data.items);
        setTotalValue(res.data.totalValue);
        console.log(res.data.items);
      }
    }
    getItemsInCart();
  }, []);

  const [modalOpen, setModal] = useState(false);

  const buyWithReneshmee = () => {
    // alert("buy")
    // Check if items is undefined or not an array
    if (!Array.isArray(items) || items.length <= 0) {
      alert("No Item Added");
    } else {
      if (loggedin) {
        toggleModal1();
      } else {
        toggleModal();
      }
    }
  };
  
  // Run the id part if items is undefined
  if (typeof items === 'undefined') {
    // Run your id part here
  }
  


  const toggleModal = () => {
    setModal(!modalOpen);
  };

  const toggleModal1 = () => {
    setPayment(!payment);
  };

  const handleIncrement = async (id, index) => {
    try {
      var form = new FormData();
      form.append("id", id);
      const res = await axiosInstance.post("/addToCart.php", form);
      setItemCount(res.data);
      setTotalValue(currentValue => currentValue + items[index].price);
      items[index].quantity += 1;
      setItems(items);
      // setTotalValue()
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  const handleDecrement = async (id, index) => {
    try {
      var form = new FormData();
      form.append("id", id);
      const res = await axiosInstance.post("/removefromcart.php", form);

      // Update the item count
      setItemCount(res.data);

      // Decrease the quantity of the current item
      items[index].quantity -= 1;
      setTotalValue(currentValue => currentValue - items[index].price);

      // If the quantity becomes zero, remove the item from the array
      if (items[index].quantity === 0) {
        const updatedItems = items.filter((item) => item.id !== id);
        setItems(updatedItems);
      } else {
        // If the quantity is not zero, update the state with the modified item
        setItems([...items]);
      }
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  console.log(items);

  return (
    <div>
      <div className="master-container">
        <div className="card-cart cart">
          <label className="title">Your cart</label>
          <div className="products">
            {items && items.length > 0 ? (
              items.map((item, index) => (
                <div style={{ margin: "10px" }} className="product" key={index}>
                  <img className="product-page-image" alt="" src={item.image} />
                  <div className="cartpage-header"
                  >
                    <span style={{ margin: "0" }}>
                      {item.name}
                    </span>
                    <p className="mobilehide" style={{ margin: "0" }}>
                      {item.price} Rs/-
                    </p>
                    {/* <p className="mobilehide">here we have to write description</p> */}
                  </div>
                  <div className="quantity">
                    <button onClick={() => handleDecrement(item.id, index)}>
                      <svg
                        fill="none"
                        viewBox="0 0 24 24"
                        height="14"
                        width="14"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linejoin="round"
                          stroke-linecap="round"
                          stroke-width="2.5"
                          stroke="#47484b"
                          d="M20 12L4 12"
                        ></path>
                      </svg>
                    </button>
                    <label>{item.quantity}</label>
                    <button onClick={() => handleIncrement(item.id, index)}>
                      <svg
                        fill="none"
                        viewBox="0 0 24 24"
                        height="14"
                        width="14"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linejoin="round"
                          stroke-linecap="round"
                          stroke-width="2.5"
                          stroke="#47484b"
                          d="M12 4V20M20 12H4"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <label className="price small">
                    {item.quantity * item.price}
                    <i
                      style={{ marginLeft: "5px" }}
                      class="fa-solid fa-indian-rupee-sign"
                    ></i>
                  </label>
                </div>
              ))
            ) : (
              <>
                <img className="emptycart" src={EmptyCart} alt="" />
                <p className="title1">No items available</p>
                {/* Add any other content or components you want to display when items are empty */}
              </>
            )}
          </div>
        </div>

        <div className="card-cart coupons">
          <label className="title">Apply coupons</label>
          <form className="form-cart">
            <input
              type="text"
              placeholder="Apply your coupons here"
              className="input_field"
            />
            <button>Apply</button>
          </form>
        </div>

        <div className="card-cart checkout">
          <div>
            <label className="title">Checkout</label>
            <div className="details">
              <span>Your cart subtotal:</span>
              <span>
                {" "}
                {totalValue} <i class="fa-solid fa-indian-rupee-sign"></i>
              </span>
              {/* <span>Discount through applied coupons:</span>
      <span>3.99 <i class="fa-solid fa-indian-rupee-sign"></i></span>
      <span>Shipping fees:</span>
      <span>4.99 <i class="fa-solid fa-indian-rupee-sign"></i></span> */}
            </div>
            <div className="checkout--footer">
              {/* <label className="price"><sup><i class="fa-solid fa-indian-rupee-sign"></i></sup>
      </label> */}
              <div
                onClick={buyWithReneshmee}
                style={{ textDecoration: "none" }}
                className="checkout-btn"
              >
                BUY{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalOpen && <Modal handleclick={toggleModal} setName={setName} />}
      {payment && <Payment  items={items} totalValue={totalValue} toggleModal1={toggleModal1} setItems={setItems} setTotalValue={setTotalValue} />}
    </div>
  );
};

export default CartPage;
