import React from 'react'
import { Link } from 'react-router-dom'
import image1 from "../Assets/similar products (1).webp"
import image2 from "../Assets/similar products (2).webp"
import image3 from "../Assets/similar products (3).webp"
import image4 from "../Assets/similar products (4).webp"
import image5 from "../Assets/similar products (5).webp"
import image6 from "../Assets/similar products (6).webp"


const SilmilerProducts = () => {
  const categoryr = "rings";
  const categoryp = "pendants";
  const categoryn = "neckless";
  const categoryb = "bracelet";
  const categoryc = "chain";
  const categoryO = "ornaments";
  const categorya = "earring";
  const categorynp = "nosepin";

  return (
    <div><h1 className="heading-main">OUR PRODUCTS</h1>
    <div  className="product-slider">
    <Link to={`/Products/${categoryr}`} className="product-view">
      <img className="product-view-image" src={image1} alt="" />
      <p className="product-view-heading"> Rings</p>
    </Link>
    <Link to={`/Products/${categorya}`} className="product-view">
      <img className="product-view-image" src={image6} alt="" />
      <p className="product-view-heading">Earrings </p>
    </Link>
    <Link to={`/Products/${categoryb}`} className="product-view">
      <img className="product-view-image" src={image5} alt="" />
      <p className="product-view-heading"> Bracelet</p>
    </Link>
    <Link to={`/Products/${categoryp}`} className="product-view">
      <img className="product-view-image" src={image3} alt="" />
      <p className="product-view-heading"> Pendents</p>
    </Link>
    <Link to={`/Products/${categoryn}`} className="product-view">
      <img className="product-view-image" src={image2} alt="" />
      <p className="product-view-heading"> Neckless</p>
    </Link>
    <Link to={`/Products/${categoryc}`} className="product-view">
      <img className="product-view-image" src={image4} alt="" />
      <p className="product-view-heading"> Silver Rings </p>
    </Link>
    </div></div>
  )
}

export default SilmilerProducts