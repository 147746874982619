import React, { useState, useEffect } from "react";
import "../Css/NavBar.css";
import Modal from "./Modal";
import navimage from "../assets-img/product (34).jpg";
import navimage1 from "../Assets/image.jpg";
import logo from "../Assets/Renesmee-logo.jpg";
import { Link } from "react-router-dom";
import Modal3 from "./Modal3";
import Mobilsnav from "./Mobilsnav";

// const NavBar = ({ itemCount, name, setName , setLoggedin }) => {
const NavBar = (props) => {
  const { itemCount, name, setName, setLoggedin, loggedin } = props;

  // Your component code
  // };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [navTop, setNavTop] = useState(true);
  const [mobileslidernav, setMobileSliderNav] = useState(false);

  // const top_nav_off = () => {
  //   setNavTop(false);
  // };

  const [modalOpen, setModal] = useState(false);
  const [logout, SetLogout] = useState(false);

  const logoutbtn = () => {
    SetLogout(!logout);
  };

  const toggleModal = () => {
    setModal(!modalOpen);
    document.body.classList.toggle("hidden-of");
  };

  const mobileslider = () => {
    setMobileSliderNav(!mobileslidernav);
    document.body.classList.toggle("hidden-of");
  };

  const closeMobileNavbar = () => {
    setMobileSliderNav(false);
  };
  const categoryr = "rings";
  const categoryp = "pendants";
  const categoryn = "neckless";
  const categoryb = "bracelet";
  const categoryc = "chain";
  const categoryO = "ornaments";
  const categorya = "earring";
  const categorynp = "nosepin";

  return (
    <div className="navbar">
      {navTop && (
        <div className="nav-top">
          <div>
            <p className="nav-top-text">
              Please mail us at info@renesmeejewel.com for any enquires. we will
              try and replying withing 48 hours of working days
            </p>
          </div>
          {/* <i
            className="fa-solid fa-xmark nav-top-close"
            onClick={top_nav_off}
          ></i> */}
          <div>
            <div className="profile-name">{name ? name.toUpperCase() : ""}</div>
            {loggedin ? (
              <>
                <Link to="MyAdmin">
                  <i className="fa-solid fa-user nav-top-close"></i>
                </Link>
              </>
            ) : (
              <>
                <i
                  className="fa-solid fa-user nav-top-close"
                  onClick={toggleModal}
                ></i>
              </>
            )}
          </div>
        </div>
      )}
      <div className="nav-top-line"></div>
      <div className="nav-bottom">
        <i
          className="mobile-nav-button fa-solid fa-bars"
          onClick={mobileslider}
        ></i>
        <Link to="/" className="logo-box">
          <img className="logo-nav1" src={logo} alt="" />
        </Link>
        <Link to="/" className="nav-link home-link">
          HOME
        </Link>
        <Link to="" className="nav-link cato-link">
          JEWELLERY
          <div className="nav-hover-cate">
            <div className="sub-cate">
              <Link
                to={`/Products/${categoryr}`}
                className="sub-cate-head"
              ></Link>
              <Link to={`/Products/${categoryr}`} className="sub-cate-link">
                Rings
              </Link>
              <Link to={`/Products/${categoryb}`} className="sub-cate-link">
                Bracelet
              </Link>
              <Link to={`/Products/${categoryc}`} className="sub-cate-link">
                Chain Set
              </Link>
              {/* <Link to={`/Products/${categoryr}`} className="sub-cate-link">
                Payal
              </Link> */}
            </div>
            <div className="sub-cate">
              {/* <Link to={`/Products/${categoryr}`} className="sub-cate-head">
              </Link> */}
              <Link to={`/Products/${categoryO}`} className="sub-cate-link">
                Ornaments
              </Link>
              <Link to={`/Products/${categorya}`} className="sub-cate-link">
                Earring
              </Link>
              {/* <Link to={`/Products/${categoryr}`} className="sub-cate-link">
                Coins for gift
              </Link> */}
              <Link to={`/Products/${categorynp}`} className="sub-cate-link">
                Nosepin
              </Link>
            </div>
            <div className="sub-cate">
              <Link
                to={`/Products/${categoryr}`}
                className="sub-cate-head"
              ></Link>
              <Link to={`/Products/${categoryr}`} className="sub-cate-link">
                Baguette Silver Collections
              </Link>
              {/* <Link to={`/Products/${categoryr}`} className="sub-cate-link">
                Nosepin
              </Link> */}
              <Link to={`/Products/${categoryp}`} className="sub-cate-link">
                Pendants
              </Link>
              <Link to={`/Products/${categoryn}`} className="sub-cate-link">
                Necklace
              </Link>
            </div>
            <div>
              <img className="nav-image" src={navimage1} alt="" />
            </div>
            <div>
              <img className="nav-image" src={navimage} alt="" />
            </div>
            {/* <div>
          <img className="nav-image" src={navimage2} alt="" />
        </div> */}
          </div>
        </Link>
        <Link to="/aboutus" className="nav-link about-link">
          ABOUT
        </Link>
        <Link to="/ContactUs" className="nav-link contact-link">
          INTERACT US
        </Link>
        <Link to="/cartpage" className="nav-link cart-link">
          CART
          <div style={{ position: "relative" }}>
            <i
              style={{
                color: "black",
                marginLeft: "5px",
                fontSize: "13px",
                marginBottom: "7px",
              }}
              className="fa-solid fa-cart-shopping"
            ></i>
            <div className="cart-number-1">{itemCount || ""}</div>
          </div>
        </Link>
        {/* <Link to="/BookMyAppointment" className="nav-link">
          BOOK APPOINTMENT
        </Link> */}
      </div>
      {isMobile ? (
        <Link to="/cartpage" className="cart-option-mobile">
          <div className="cart-number-2">{itemCount || ""}</div>
          <i className="mobile-cart fa-solid fa-cart-shopping"></i>
        </Link>
      ) : (
        <div className="cart-option-mobile1">
          <i className="fa-brands fa-whatsapp mobile-whatsapp"></i>
        </div>
      )}

      {/* {modalOpen && <Modal handleclick={toggleModal} setName={setName} setLoggedin={setLoggedin} name={name} loggedin={loggedin} />} */}
      {modalOpen && <Modal handleclick={toggleModal} />}

      {logout && <Modal3 handleclick={logoutbtn} setName={setName} />}

      {mobileslidernav && (
        <Mobilsnav
          handleclick={mobileslider}
          itemCount={itemCount}
          onClose={closeMobileNavbar}
        />
      )}
    </div>
  );
};

export default NavBar;
