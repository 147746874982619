import React from "react";
import "../Css/AboutUsPage.css"; // Import the corresponding CSS file
import SliderMain from "../Components/SliderMain";

const AboutUs = () => {
  return (
    <>
     <div className="aboutus-top">
  <h1 className="heading-main">Welcome to Our E-Commerce Store!</h1>
</div>
<SliderMain />

<div className="about-us-container">
  <div className="about-container-top">
    <h1 className="heading-main">Our Story</h1>
  </div>

  <div>
    <p className="content-paragraph">
      Renesmee means "born out of love," but this Renesmee was born out of a dream. It started in 2014 when I had a dream of a spacious jewelry shop. At that time, I was working in a jewelry showroom, and I thought the shop I saw in my dream was an extended version of the current shop. So, I spoke with my boss [Sir] about it, and he was happy and decided to buy a shop. They bought it in 2018, and now it's running successfully by the grace of God. But there was something missing. I also wanted to do something, but managing a jewelry shop solely was beyond impossible for a working woman like me. At that sharp moment, I was inspired by God to open an e-commerce showroom. This thought came to mind instantly, and now, it's a dream within a dream in front of you all. So, what I learned from this is when you dream for someone else and dedicate yourself to fulfilling their dream, God fulfills yours surely greater than theirs. We always talk about hard work, effort, and dedication, but one thing I want to add is the spirit of being a well-wisher. Be a well-wisher in any situation, even if it's against you, even if you bear a loss. God is waiting to give so much more than you give to someone else.
    </p>
  </div>

  <div className="about-container">
    <h1 className="heading-main">Orders & Specializations</h1>
  </div>
  <div>
    <p className="content-paragraph">
      Please contact us for customization orders. We would like to inform our customers that we also manufacture diamond and gold jewelry. For further information, please email us at hey@renesmee.com. We would love to create stunning jewelry for you.
    </p>
  </div>

  <div className="about-container">
    <h1 className="heading-main">Make Your Jewelry Last Longer</h1>
  </div>
  <div>
    <p className="content-paragraph">
      <ul>
        <li>Store your jewelry in the jewelry box after using it. Keep it dry.</li>
        <li>Mildly rubbing with a silver cleaning cloth will restore the original luminosity. You can buy a silver cleaning cloth from our website.</li>
        <li>Do not use harsh soap or cleansers on jewelry.</li>
        <li>Try to remove your jewelry before bed, swimming, and makeup because of metal, stone, and diamond sensitivity.</li>
        <li>Regularly use and polish your ornaments after a significant period to prevent fading.</li>
      </ul>
    </p>
  </div>

  <div className="about-container">
    <h1 className="heading-main">Shipping Policy - Free Shipping</h1>
  </div>
  <div>
    <p className="content-paragraph">
      <ul>
        <li>Renesmee ensures that your order reaches you in the fastest time and in good condition.</li>
        <li>Usually, orders are shipped and delivered in 10-12 days. In fortunate cases, a slight delay might be caused due to natural causes and government holidays.</li>
      </ul>
    </p>
  </div>

  <div className="about-container">
    <h1 className="heading-main">Returns and Exchange Policy</h1>
  </div>
  <div>
    <p className="content-paragraph">
      Renesmee Jewels are designed to feel like cherished heirlooms that you'll treasure for a lifetime. All jewelry is thoroughly checked before dispatch. However, in the event that you notice any damage or issues during shipping, kindly reach out to us via chat or email within 48 hours of receiving the jewelry to report any problems. We will replace it. Please note that Renesmee does not offer exchanges on purchases for hygiene reasons. If you wish to request an exchange for any reason, please email us at hey@renesmee.com. Kindly note that you will bear the shipping charges for returning the jewelry to us. Upon receiving the jewelry, we will then ship the replacement to you.
    </p>
  </div>

  <div className="about-container">
    <h1 className="heading-main">Refunds</h1>
    <p className="content-paragraph">
      Renesmee Jewels does not offer refunds on purchases. All sales are final. Feel free to contact us regarding your special order or requests at hey@renesmee.com.
    </p>
  </div>

  <div className="about-container">
    <h1 className="heading-main">Privacy Policy</h1>
  </div>
  <div>
    <p className="content-paragraph">
      <ul>
        <li>You may not copy, post, display, download, reproduce, or republish any part of the website without permission.</li>
        <li>Protecting and securing your personal information is one of the highest responsibilities of Renesmee.</li>
        <li>The information you provide is referred to as personal information. Kindly make sure you update your information if there are any changes.</li>
        <li>Renesmee uses the information you provide only to fulfill your order and communicate with you.</li>
        <li>For any further questions, please contact us at hey@renesmee.com.</li>
      </ul>
    </p>
  </div>
  <br />
  <p className="content-paragraph">Happy Shopping!</p>
  <br />
</div>

    </>
  );
};

export default AboutUs;
