import React, { useState } from 'react'
// import Modal2 from './Modal2';
// import axios from 'axios';
import { axiosInstance } from '../../utils';
// import { useNavigate } from 'react-router-dom';


const Modal3 = (props ) => {
const [modalOpen, setModal] = useState(false);
const [loggedin, setLoggedin] = useState(false)

const toggleModal = () => {
setModal(!modalOpen);

};

// const naviget = useNavigate();

const [username, setUsername] = useState("");
const [password, setPassword] = useState("");



const handleFormSubmit = () => {
  console.log("username:", username);
  console.log("password:", password);

  if (username.length === 0) {
    alert("Username has left Blank");
  } else if (password.length === 0) {
    alert("password has left Blank");
  } else {

    const url = "/signin.php";

    let fData = new FormData();
    fData.append("username", username);
    fData.append("password", password);

    axiosInstance
      .post(url, fData)
      .then((response) => {
        console.log(response.data);
        if (response.data.length <= 20) {
          alert("welcome");
          props.handleclick();
          props.setName(response.data);
          setLoggedin(true)
    
        } else {
          alert("You Have Entered Wrong Userid Or Password");
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Error");
      });
  }
};

// if(loggedin === true){
//     document.getElementById("").style.display = "none";
// }


  return (
    <div>
       


    </div>
     

          
 
  )
}

export default Modal3;




