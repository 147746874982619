import React, { useContext, useState } from "react";
import Modal2 from "./Modal2";
import { axiosInstance } from "../../utils";
import { MyContext } from "../MyApp";


const Modal = ({handleclick}) => {
const {  name, setName, setLoggedin, loggedin } = useContext(MyContext);
  // const { setLoggedin, setName } = ; 
  const [modalOpen, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modalOpen);
  };
  console.log(loggedin);
  console.log(name);
 
  const [showPassword1  , setShowPassword1] = useState(false);

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };


  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleFormSubmit = async () => {
    if (username.trim().length === 0) {
      alert("Username is required");
    } else if (password.trim().length === 0) {
      alert("Password is required");
    } else if (username.length < 4 || username.length > 20) {
      alert("Username must be between 4 and 20 characters");
    } else if (password.length < 6) {
      alert("Password must be at least 6 characters");
    } else {
      const url = "/signin.php";

      let fData = new FormData();
      fData.append("username", username);
      fData.append("password", password);

      try {
        const response = await axiosInstance.post(url, fData);
        console.log(response.data);
        if (response.data) {
          alert("Welcome: " + response.data[0].name);
          setLoggedin(true);
          handleclick();

          // Convert user data object to JSON string
          const userDataString = JSON.stringify(response.data);

          // Store JSON string in session storage
          sessionStorage.setItem("user", userDataString);

          // Store JSON string in local storage
          localStorage.setItem("user", userDataString);

          setName(response.data[0].name);
        } else {
          alert("You Have Entered Wrong Userid Or Password");
        }
      } catch (error) {
        console.error(error);
        alert("You Have Entered Wrong Userid Or Password");
        
      }
    }
  };

  return (
    <div className="modal-back">
      <div>
        <div className="form">
          <i className="fa-solid fa-xmark xmark" onClick={handleclick}></i>
          <p id="heading">Login</p>
          <div className="field">
            <svg
              className="input-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914z"></path>
            </svg>
            <input
              autocomplete="off"
              placeholder="Mobile No or E-Mail"
              className="input-field"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="field">
            <svg
              className="input-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"></path>
            </svg>
            <input
              placeholder="Password"
              className="input-field"
              type={showPassword1 ? "text" : "password"}
              
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

<div
              onClick={togglePasswordVisibility1}
              style={{ cursor: "pointer" }}
            >
              {showPassword1 ? (
                <i className="fa-solid fa-eye-slash"></i>
              ) : (
                <i className="fa-solid fa-eye"></i>
              )}
            </div>
          </div>

          <div className="btn">
            <button className="button1" onClick={handleFormSubmit}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Login&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </button>
            <button onClick={toggleModal} className="button2">
              Sign Up
            </button>
          </div>
          <button className="button3">Forgot Password</button>
        </div>
      </div>

      {modalOpen && <Modal2 handleclick={toggleModal} />}
    </div>
  );
};

export default Modal;
