import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../utils";

const ProductSlider = (props) => {
  const [products, setProducts] = useState([]);

  const [slidesToShow, setSlidesToShow] = useState(4); // Default number of slides to show

  useEffect(() => {
    const handleResize = () => {
      // Adjust the number of slides shown based on screen width
      if (window.innerWidth < 468) {
        setSlidesToShow(2);
      } else if (window.innerWidth < 950) {
        setSlidesToShow(2);
      } else if (window.innerWidth < 1250) {
        setSlidesToShow(3);
      } else {
        setSlidesToShow(4); // Default number of slides for larger screens
      }
    };

    // Set initial number of slides based on the initial screen width
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    async function getProducts() {
      const res = await axiosInstance.post("/getProducts.php");
      if (res?.data) {
        setProducts(res.data.products);
      }
    }
    getProducts();
  }, []);

  // Fetch all products function
  // const fetchAllProducts = async () => {
  //   const res = await axiosInstance.post("/getProducts.php");
  //   if (res?.data) {
  //     setProducts(res.data.products);
  //   }
  // };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Set autoplay speed in milliseconds (e.g., 3000ms = 3 seconds)
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {" "}
      <Slider className="product-slider" {...settings}>
        {products.map((product) => (
          <Link
            to={`/ProductDetailPage/${product.id}`}
            className="ProductViewer"
            key={product.id}
          >
            <div className="ProductViewer-details">
              <img className="product-image-main" alt="" src={product.image} />
              <p className="text-title">{product.name.toUpperCase()}</p>
              <p className="text-body">
                {product.price === "0"
                  ? "Sold Out"
                  : `Price: ${product.price} ₹`}
              </p>
              {product.weight > 0 ? (
                <p className="text-body">
                  {product.weight === "" ? "" : `Weight : ${product.weight} gm`}{" "}
                </p>
              ) : (
                <></>
              )}
            </div>

            <div className="ProductViewer-button">
              Shop Now
              <i
                style={{ color: "white", marginLeft: "5px" }}
                className="fa-solid fa-cart-shopping"
              ></i>
            </div>
          </Link>
        ))}
      </Slider>
    </div>
    // </div>
  );
};

export default ProductSlider;
