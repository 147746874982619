import React from 'react'

const Loader = () => {
  return (
    <div className='modal-back'>
    <div class="loader ">
    <span class="bar"></span>
    <span class="bar"></span> 
    <span class="bar"></span>
    </div>
</div>
  )
}

export default Loader