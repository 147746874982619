import React from 'react'
import { Link } from 'react-router-dom'

const ProductCard = (props) => {
  return (
    <div>  <div className="product-card">
    <div className="card">
        <img className='product-image' src={props.image} alt="" />
      <div className="card__content">
        <p className="card__title">{props.name}</p>
        <p className="card__description">
        Welcome to our jewelry shop, where dreams are adorned with exquisite brilliance. Step into a world of timeless elegance and contemporary sophistication, where each piece tells a story of craftsmanship and beauty. From dazzling diamonds to lustrous pearls, our curated collection caters to every taste and occasion
         {/* {props.desc} */}
        </p>
        <Link style={{textDecoration:"none"}} to={`/products/${props.name}`} className="card__button">Open Product</Link>
        {/* <button className="card__button secondary">Add to cart</button> */}
      </div>  
    </div>
    <p style={{marginTop:"15px"}}>{props.name}</p>
  </div></div>
  )
}

export default ProductCard