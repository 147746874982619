// import axios from "axios";
import React, { useState } from "react";
import { axiosInstance } from "../../utils";

const Modal2 = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [reenterPassword, setReenterPassword] = useState("");

  const [showPassword1  , setShowPassword1] = useState(false);

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  const [showPassword2, setShowPassword2] = useState(false);

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  function validatePassword(password) {
    const hasNumber = /[0-9]/.test(password);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);

    return hasNumber && hasSymbol && hasUppercase;
  }

  const handleFormSubmit = () => {
    if (password !== reenterPassword) {
      alert("Passwords do not match");
    } else {
      if (name.trim().length === 0) {
        alert("Name is required");
      } else if (!/^\S+@\S+\.\S+$/.test(email)) {
        alert("Please enter a valid email address");
      } else if (!/^\d{10}$/.test(mobile)) {
        alert("Please enter a valid 10-digit mobile number");
      } else if (address.trim().length === 0) {
        alert("Address is required");
      } else if (password.length < 6) {
        alert("Password must be at least 6 characters");
      } else if (!validatePassword(password)) {
        alert(
          "Password must contain at least one numeric value, one symbolic value, and one uppercase letter"
        );
      } else {
        const url = "/signup.php";

        let fData = new FormData();
        fData.append("name", name);
        fData.append("mobile", mobile);
        fData.append("email", email);
        fData.append("address", address);
        fData.append("password", password);

        axiosInstance
          .post(url, fData)
          .then((response) => {
            alert("You have successfully signed up");
            props.handleclick();
          })
          .catch((error) => {
            console.log(error);
            alert("Error");
          });
      }
    }
  };

  return (
    <div className="modal-back">
      <div>
        <div className="form-signup">
          <i
            className="fa-solid fa-xmark xmark"
            onClick={props.handleclick}
          ></i>
          <p id="heading">Sign up</p>
          <div className="field">
            <input
              autocomplete="off"
              placeholder="Name"
              className="input-field"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="field">
            <input
              placeholder=" E-mail"
              className="input-field"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="field">
            <input
              placeholder="Mobile No"
              className="input-field"
              type="number"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </div>

          <div className="field">
            <input
              placeholder="Address"
              className="input-field"
              type="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>

          <div className="field">
            <input
              placeholder="Password"
              className="input-field"
              type={showPassword1 ? "text" : "password"}
              
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

<div
              onClick={togglePasswordVisibility1}
              style={{ cursor: "pointer" }}
            >
              {showPassword1 ? (
                <i className="fa-solid fa-eye-slash"></i>
              ) : (
                <i className="fa-solid fa-eye"></i>
              )}
            </div>
          </div>

          <div className="field">
            <input
              placeholder="ReEnter-Password"
              className="input-field"
              type={showPassword2 ? "text" : "password"}
              value={reenterPassword}
              onChange={(e) => setReenterPassword(e.target.value)}
            />
            <div
              onClick={togglePasswordVisibility2}
              style={{ cursor: "pointer" }}
            >
              {showPassword2 ? (
                <i className="fa-solid fa-eye-slash"></i>
              ) : (
                <i className="fa-solid fa-eye"></i>
              )}
            </div>
          </div>

          {/* <div className="field">
   
   <input placeholder="Re-enter password" className="input-field" type="password" />
 </div> */}

          <button className="button3" onClick={handleFormSubmit}>
            Sign Up
          </button>
          {/* <button className="button3">Forgot Password</button> */}
        </div>
      </div>
    </div>
  );
};

export default Modal2;
