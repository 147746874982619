import { createContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./Pages/Homepage.jsx";
import NavBar from "./Components/NavBar.jsx";
import Footer from "./Components/Footer.jsx";
import CartPage from "./Pages/CartPage.jsx";
import AntiqueSet from "./Pages/ChildPages/Products/AntiqueSet.jsx";
import Bracelet from "./Pages/ChildPages/Products/Bracelet.jsx";
import ChainSet from "./Pages/ChildPages/Products/ChainSet.jsx";
import CoinsForGift from "./Pages/ChildPages/Products/CoinsForGift.jsx";
import DiamondNosepin from "./Pages/ChildPages/Products/DiamondNosepin.jsx";
import GoldNosepin from "./Pages/ChildPages/Products/GoldNosepin.jsx";
import LoveAndEngagement from "./Pages/ChildPages/Products/LoveAndEngagement.jsx";
// import Necklace from "./Pages/ChildPages/Products/Necklace.jsx";
import Payal from "./Pages/ChildPages/Products/Payal.jsx";
import Pendants from "./Pages/ChildPages/Products/Pendants.jsx";
import Rings from "./Pages/ChildPages/Products/Rings.jsx";
import Diamond from "./Pages/ChildPages/Diamond.jsx";
import Gold from "./Pages/ChildPages/Gold.jsx";
import Silver from "./Pages/ChildPages/Silver.jsx";
import ContactUs from "./Pages/ContactUs.jsx";
import ProductDetailPage from "./Pages/ProductDetailPage.jsx";
import ornaments from "./Pages/ChildPages/Products/Oranaments.jsx";
import { axiosInstance } from "../utils.js";
import Products from "./Pages/Products.jsx";
import AboutUs from "./Pages/AboutUs.jsx";
import MobileFootBtn from "./Components/MobileFootBtn.jsx";
import MyAdmin from "./Pages/MyAdmin.jsx";
import Orderlist from "./Components/Orderlist.jsx";
import Loader from "./Components/Loader.jsx";
import UserOrderList from "./Pages/UserOrderList.jsx";

// Create a context
const MyContext = createContext();

const MyApp = () => {
  const [itemCount, setItemCount] = useState(0);
  const [Loading , setLoading] = useState(false)
  const [loggedin, setLoggedin] = useState(localStorage.getItem('user') ? true : false)
  const [name, setName] = useState();

  useEffect(() => {
    // Retrieve user data from local storage
    const userDataString = localStorage.getItem("user");
  
    if(userDataString){
      // Parse the JSON string back to an object
      const userData = JSON.parse(userDataString);
  
      // Access the 'name' property from the user data object
      setName(userData[0].name);
    }
  }, []); 

  useEffect(() => {
    async function getItemCountInCart() {
      const res = await axiosInstance.post('/cartItemCount.php');
      setItemCount(res.data);
    }
    getItemCountInCart();
  }, []);

  return (
    <MyContext.Provider value={{ itemCount, setItemCount, loggedin, setLoggedin, name, setName , setLoading }}>
      <div>   
     <NavBar itemCount={itemCount} name={name} setName={setName} setLoggedin={setLoggedin} loggedin={loggedin} />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/CartPage" element={<CartPage setItemCount={setItemCount} setName={setName} loggedin={loggedin} />} />
          <Route path="/AntiqueSet" element={<AntiqueSet />} />
          <Route path="/Bracelet" element={<Bracelet />} />
          <Route path="/ChainSet" element={<ChainSet />} />
          <Route path="/CoinsForGift" element={<CoinsForGift />} />
          <Route path="/DiamondNosepin" element={<DiamondNosepin />} />
          <Route path="/GoldNosepin" element={<GoldNosepin />} />
          <Route path="/LoveAndEngagement" element={<LoveAndEngagement />} />
          {/* <Route path="/Necklace" element={<Necklace />} /> */}
          <Route path="/ornaments" element={<ornaments />} />
          <Route path="/Payal" element={<Payal />} />
          <Route path="/Pendants" element={<Pendants />} />
          <Route path="/Diamond" element={<Diamond />} />
          <Route path="/Gold" element={<Gold />} />
          <Route path="/Silver" element={<Silver />} />
          <Route path="/Rings" element={<Rings />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/Product" element={<Products />} />
          <Route path="/Products/:LinkValue" element={<Products />} />
          <Route path="/UserOrderList" element={<UserOrderList />} />
          <Route path="/OrderList" element={<Orderlist />} />
          <Route path="/MyAdmin" element={<MyAdmin />} />

          <Route path="/ProductDetailPage/:id" element={<ProductDetailPage setItemCount={setItemCount} />} />
        </Routes>
        {Loading && (<>himsnhdu
          <Loader/>
        </>
        )}
        <MobileFootBtn/>
        <Footer />
      </div>
    </MyContext.Provider>
  );
};

export default MyApp;

export { MyContext };
