import React from "react";
import ProductViewer from "../../../Components/ProductViewer";

const AntiqueSet = () => {
  return (

    <>
         <div className='product-heading'>
      <h1 className="heading-main"> Antique Set</h1>
      </div>
    <div className='ProductViewer-row'>
       
  <div> <ProductViewer /></div>
  <div> <ProductViewer /></div>
  <div> <ProductViewer /></div>
</div>
    </>
    )

};

export default AntiqueSet;
