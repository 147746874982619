import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const MyAdmin = () => {
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState(null);

  useEffect(() => {
    // Retrieve user details from local storage
    const storedUserDetail = localStorage.getItem("user");

    // Parse the stored data if it exists
    if (storedUserDetail) {
      const parsedUserDetail = JSON.parse(storedUserDetail);
      setUserDetail(parsedUserDetail);
    }
  }, []);

  console.log(userDetail)

  const Logout = () => {
    localStorage.removeItem("user");
    navigate("/");
    window.location.reload()
  };


  return (
    <div className="myadminpage">
      <h1 className="heading-main"> <i class="fa-solid fa-user" style={{fontSize:"18px"}}></i> User Detail </h1>
      {userDetail && (
        <div className="myadminpage-center">
          <div className="">
            <p>Name 
            <div>
      {userDetail[0].name.toUpperCase()}
    </div>
              </p>

          </div>
          <div className="">
            <p>Email 
              <div>
              {userDetail[0].email}

              </div>
            </p>
          </div>
          <div className="">
            <p>
              Address
               <div>
               {userDetail[0].address}

               </div>

            </p>
          </div>
          <div className="">
            <p>
              Password  
              <div>
              {userDetail[0].password}

              </div>
              </p>
          </div>
          <div className="">
            <p>
              Mobile
              
               <div>
               {userDetail[0].mobile}

               </div>
               </p>
          </div>
       
        <div className="details-button-box"style={{margin:"30px auto" }} >
                <Link to="/UserOrderList" className="button" >
                  <i
                    style={{ color: "white", fontSize: "16px" }}
                    className="fa-solid fa-cart-shopping svg-icon"
                  ></i>
                  <span className="lable">Show my Order List</span>
                </Link>
                <section 
                  className="button"
                  onClick={Logout}
                  style={{ textDecoration: "none", background:"#f23636", height: "45px" }}
                >
                  <i
                    style={{ color: "white", fontSize: "16px" }}
                    className="fa-solid fa-right-from-bracket svg-icon"
                  ></i>
                  {/* <i class="fa-solid fa-right-from-bracket"></i> */}
                  <diva className="lable"> LogOut</diva>
                </section>
              </div>
    </div>
     )}
     </div>
  );
};

export default MyAdmin;
