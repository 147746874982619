import React, { useContext, useEffect, useState } from "react";
import paymeniimage from "../Assets/payment.png";
import { axiosInstance } from "../../utils";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../MyApp";

const Payment = (props) => {
  const [inputValues, setInputValues] = useState({});
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState(null);
  const {setItemCount} = useContext(MyContext)

  const clearCartSession = async () => {
    const url = "/clearCartSession.php"; // Ensure you have this endpoint set up on the backend
    try {
      await axiosInstance.post(url);
      console.log("Cart session cleared!");
    } catch (error) {
      console.error("Error clearing cart session:", error);
    }
  };
  useEffect(() => {
    // Retrieve user details from local storage
    const storedUserDetail = localStorage.getItem("user");

    // Parse the stored data if it exists
    if (storedUserDetail) {
      const parsedUserDetail = JSON.parse(storedUserDetail);
      setUserDetail(parsedUserDetail);
    }
  }, []);
  const paymentDone = async () => {
    debugger;
    console.log(userDetail);
    const jsonString = JSON.stringify(props.items);
    console.log(jsonString);

    const transactionId = document.querySelector(".transitionIdInput").value; // Get transactionId from input field
    const orderDetails = {
      name: userDetail[0].name,
      address: userDetail[0].address,
      mobile: userDetail[0].mobile,
      email: userDetail[0].email,
      items: jsonString,
      amount: props.totalValue,
      transactionId: transactionId, // Include transactionId in orderDetails
    };

    const url = "/MyOrder.php";
    let formData = new FormData();
    for (const key in orderDetails) {
      formData.append(key, orderDetails[key]);
    }
    // Make a POST request to send email
    axiosInstance
      .post(url, formData)
      .then((response) => {
        alert("Thanks for Your Order");
        props.setTotalValue(0)
        props.setItems([])
        clearCartSession();
        sendEmail(orderDetails);
        setItemCount(0)
        navigate("/");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const sendEmail = (orderDetails) => {
    const url = "/sendEmail.php";
    // Construct FormData object with orderDetails
    let formData = new FormData();
    for (const key in orderDetails) {
      formData.append(key, orderDetails[key]);
    }
    // Make a POST request to send email
    axiosInstance
      .post(url, formData)
      .then((response) => {
        console.log("Email sent successfully!");
        // alert("Thanks for Your Order");
        navigate("/");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="modal-back">
      <div className="payment-box">
        <i
          style={{ color: "black" }}
          className="fa-solid fa-xmark xmark"
          onClick={props.toggleModal1}
        ></i>
        <img src={paymeniimage} alt="" />
        <p style={{ margin: "20px" }}>
          You have to pay {props.totalValue} Rs Here
        </p>
        <p>After payment Write Your Transition id here</p>
        <input type="text" className="transitionIdInput" />
        <button
          style={{ color: "white", background: "black" }}
          className="button"
          onClick={paymentDone}
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default Payment;
