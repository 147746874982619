import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import axios from 'axios';
import { axiosInstance } from "../../utils";
import ReactImageMagnify from "react-image-magnify";

const ProductDetailPage = ({ setItemCount }) => {
  const [data, setData] = useState([]);
  const { id } = useParams();

  console.log(data);

  useEffect(() => {
    const searchproductindatabase = () => {
      const url = "/getproductforcart.php";
      let fData = new FormData();
      fData.append("id", id);

      axiosInstance
        .post(url, fData)
        .then((response) => {
          // Assuming your response data is an object with a 'products' property
          const data = response.data.products; // Assuming the data property contains your data
          setData(data);
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
          alert("Error");
        });
    };

    searchproductindatabase();
  }, []);

  const addToCart = async (id) => {
    try {
      var form = new FormData();
      form.append("id", id);
      const res = await axiosInstance.post("/addToCart.php", form);
      setItemCount(res.data);
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  return (
    <div>
      {Array.isArray(data) &&
        data.map((d) => (
          <div className="productpage">
            <div className="productpage-left" key={d.id}>
              <div className="product-image-detail">
                <ReactImageMagnify
                  {...{
                    smallImage: {
                      // alt: 'Wristwatch by Ted Baker London',
                      isFluidWidth: true,
                      src: d.image,
                    },
                    largeImage: {
                      src: d.image,
                      width: 1200,
                      height: 1200,
                    },
                    enlargedImageContainerDimensions: {
                      width: "300%",
                      height: "100%",
                    },
                  }}
                />
              </div>

              <div className="div-images">
                <img className="" alt="" src={d.Image2} />
                <img className="" alt="" src={d.Image3} />
                <img className="" alt="" src={d.Image4} />
              </div>
            </div>
            <div className="productpage-right">
              <div className="productdetail-text-box">
                <h1 className="product-font">Product price</h1>
                <h2 className="product-n">{d.name}</h2>
                <h3 className="product-p">
                  <i
                    style={{ margin: "3px" }}
                    className="fa-solid fa-indian-rupee-sign"
                  ></i>
                  {d.price}
                  {/* <i  style={{margin:"3px"}}  className="fa-solid fa-circle-info"></i> */}
                </h3>
                <h3 className="product-r">
                  {/* <i className="fa-solid fa-indian-rupee-sign"  style={{margin:"3px"}} ></i> 9000 */}
                </h3>
                {/* <h3 className="product-percentage"> -30% off</h3> */}
              </div>
              <div className="productdetail-text-box">
                <h1 className="product-font">Product Description</h1>
                {/* <h2 className='product-n'>Silver Ring</h2> */}
                <h3 className="product-p">
                Silver jewelry offers timeless elegance, adding a touch of sophistication to any ensemble with its lustrous appeal and versatile charm.
{/* Crafted with meticulous attention to detail, silver jewelry showcases exquisite designs, ranging from delicate filigree to bold statement pieces, catering to every style and occasion. */}
{/* Embracing both tradition and modernity, silver jewelry symbolizes purity and grace, making it a cherished choice for expressing individuality and celebrating moments of significance. */}
                </h3>
                {/* <h3 className='product-r'><i className="fa-solid fa-indian-rupee-sign"></i> 9000  </h3>
            <h3 className='product-percentage'> -30%  off</h3> */}
              </div>
              <div className="productdetail-text-box">
                <h1 className="product-font">Product Detail</h1>
                {d.size && (
                  <h3 className="product-percentage">Size: {d.size}</h3>
                )}
                {d.weight && (
                  <h3 className="product-percentage">Weight: {d.weight} Gm</h3>
                )}
              </div>
              <div className="details-button-box">
                <button className="button" onClick={() => addToCart(id)}>
                  <i
                    style={{ color: "white", fontSize: "16px" }}
                    className="fa-solid fa-cart-shopping svg-icon"
                  ></i>
                  <span className="lable">Add to Cart</span>
                </button>
                <Link
                  className="button"
                  style={{ textDecoration: "none", height: "35px" }}
                  to="/product"
                >
                  <i
                    style={{ color: "white", fontSize: "16px" }}
                    className="fa-solid fa-cart-shopping svg-icon"
                  ></i>
                  <div className="lable"> Show More Products</div>
                </Link>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ProductDetailPage;
