import React from "react";
import "../Css/Footer.css";
import { Link } from "react-router-dom";
const Footer = () => {
  const phoneNumber = "9870284371"; // Replace with the desired phone number
  const message = " "; // Replace with your desired message

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;

  const categoryr = "rings";
  const categoryp = "pendants";
  const categoryn = "Neckless";
  const categoryb = "Bracelet";
  const categoryc = "Chain";
  const categoryO = "ornaments";
  const categorya = "Earring";
  const categorynp = "Nosepin";

  return (
    <div className="footer">
      <div className="footer-hover-cate">
        <h4>About Us</h4>
        <p>
          Renesmee means "born out of love," but this Renesmee was born out of a
          dream. It started in 2014 when I had a dream of a spacious jewelry
          shop. At that time, I was working in a jewelry showroom, and I thought
          the shop I saw in my dream was an extended version of the current
          shop.
        </p>
      </div>

      <div className="footerdetails">
        <Link to="cartpage"> My Cart</Link>
        <Link to="AboutUs">About Us</Link>
        <Link to="ContactUs">Contact Us</Link>
        <Link to="AboutUs">Return and Exchange</Link>
      </div>

      <div className="footer-icon-box">
        <div className="wrapper">
          <a
            href="https://www.facebook.com/profile.php?id=61553984426893"
            className="icon facebook"
          >
            <span className="tooltip">Facebook</span>
            <span>
              <i className="fab fa-facebook-f"></i>
            </span>
          </a>
          <a
            href={whatsappLink}
            target="_blank"
            rel="noopener noreferrer"
            className="icon twitter"
          >
            <span className="tooltip">Whatsapp</span>
            <span>
              <i className="fab fa-whatsapp"></i>
            </span>
          </a>
          <a
            href="https://www.instagram.com/renesmeejewel_official"
            className="icon instagram"
          >
            <span className="tooltip">Instagram</span>
            <span>
              <i className="fab fa-instagram"></i>
            </span>
          </a>
          <a
            href="  https://youtube.com/@RenesmeeJewel?si=FvCj1_W3afaM55BG"
            className="icon youtube"
          >
            <span className="tooltip">Youtube</span>
            <span>
              <i className="fa-brands fa-youtube"></i>
            </span>
          </a>
        </div>
        <p>Mail-info@renesmeejewel.com</p>

        <p>Contact-+91 8928459292</p>
      </div>
    </div>
  );
};

export default Footer;
